import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Newsletter from '../components/Newsletter'
import { getUrlParameter } from '../utils/'

const ref = getUrlParameter('r')

const referrer = { dev: 'Dev.to' }[ref]

export default function NewsletterPage({ data, location }) {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Newsletter"
        keywords={[`blog`, `python`, `javascript`, `react`, `newsletter`]}
      />
      <Newsletter referrer={`newsletter page ${ref}`}>
        <p>
          Welcome, {referrer} friend! If you like my content, you should join my
          newsletter. I send it out a few times a month, and it includes extra
          content that I don't publish on my site. There's never any spam, and
          you can unsubscribe at any time.
        </p>
      </Newsletter>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
